// /**
//  * @namespace components
//  */

// import React from 'react';
// import classNames from 'classnames';
// import PropTypes from 'prop-types';
// import { miniGazelle, replacePatternAttributes } from '../../../helpers';

// import './StandardText.scss';

// /**
//  * @class StandardText
//  *
//  * StandardText module with configuration options.
//  *
//  * @memberof components
//  *
//  *
//  * @property {object} data - Primary object to insert data props into component
//  * @property {string=} data.title - Display title from Protein
//  * @property {string=} data.content - HTML content payload from protein
//  * @property {string} data.alignment - Text alignment, one of: left, center, right
//  * @property {object=} data.patternAttributes - Pattern Attributes object in key: value format for dynamically updating labels and static text with variables
//  * @property {bool=} data.largeText - Changes the text size to large
//  * @property {string} data.logicalName - Logical name of the module, used as the HTML node ID
//  * @property {string} data.backgroundImage - Link to the background image
//  * @property {string} data.backgroundColor - Background color in valid CSS hexadecimal, rgb or string value
//  * @property {string=} data.textcolor - Color of the block link text in valid CSS hexadecimal, rgb or string value
//  * @property {string=} data.removeTopPadding - Boolean switch to reduce the top padding of the component
//  * @property {string=} data.removeBottomPadding - Boolean switch to reduce the bottom padding of the component
//  * @property {string=} data.titleLabel - Label key for overriding the title
//  * @property {string=} data.contentLabel - Label key for overriding the content
//  * @property {string} data.header - Select box for choosing what flavour of header element is used in render
//  *
//  * @example
//  * return (
//  *   <StandardText data={data} />
//  * )
//  */

// const StandardText = (props = {}) => {
//     const { data = {} } = props;
//     const {
//         patternAttributes = {},
//         titleLabel,
//         contentLabel,
//         title: titleFromProps,
//         content: contentFromProps,
//         largeText,
//         backgroundColor,
//         backgroundImage,
//         textColor,
//         logicalName,
//         removeBottomPadding,
//         removeTopPadding,
//         alignment,
//         header = 'h1'
//     } = data;

//     const title = titleLabel ? miniGazelle.label(titleLabel, true) : titleFromProps;
//     const content = contentLabel ? miniGazelle.label(contentLabel, true) : contentFromProps;

//     /**
//      * build the standard style props
//      **/

//     const inlineStyle = {
//         backgroundColor: backgroundColor,
//         backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
//         color: textColor
//     };

//     const headerStyle = {
//         color: textColor
//     };

//     const wrapperClasses = classNames({
//         'container-fluid': true,
//         module: true,
//         'module-large-text': largeText,
//         'standard-text': true,
//         'standard-text-no-title': !title,
//         'remove-bottom-padding': removeBottomPadding,
//         'remove-top-padding': removeTopPadding
//     });

//     const classes = classNames({
//         'col-md-12 text-left': alignment === 'left' || !alignment,
//         'col-md-10 offset-md-1 text-center': alignment === 'center',
//         'col-md-12 text-right': alignment === 'right'
//     });

//     const HeaderElement = header ? header : 'h2';

//     return (
//         <div className={wrapperClasses} id={logicalName} style={inlineStyle}>
//             <div className="row">
//                 <div className="container">
//                     <div className="row">
//                         <div className={classes}>
//                             {title && (
//                                 <HeaderElement
//                                     className=""
//                                     style={headerStyle}
//                                     dangerouslySetInnerHTML={{
//                                         __html: replacePatternAttributes({
//                                             string: title,
//                                             patternAttributes
//                                         })
//                                     }}
//                                 />
//                             )}
//                             {content && (
//                                 <div
//                                     className="inner-content"
//                                     dangerouslySetInnerHTML={{
//                                         __html: replacePatternAttributes({
//                                             string: content,
//                                             patternAttributes
//                                         })
//                                     }}
//                                 />
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// StandardText.propTypes = {
//     data: PropTypes.shape({
//         title: PropTypes.string,
//         content: PropTypes.string,
//         alignment: PropTypes.oneOf(['left', 'center', 'right']),
//         patternAttributes: PropTypes.object,
//         largeText: PropTypes.bool,
//         logicalName: PropTypes.string.isRequired,
//         backgroundColor: PropTypes.string,
//         backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//         textColor: PropTypes.string,
//         titleLabel: PropTypes.string,
//         contentLabel: PropTypes.string,
//         removeBottomPadding: PropTypes.bool,
//         removeTopPadding: PropTypes.bool,
//         header: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
//     }).isRequired
// };

// export default StandardText;

import React from 'react';
import classNames from 'classnames';
import { miniGazelle, replacePatternAttributes } from '../../../helpers';

import './StandardText.scss';

class StandardText extends React.Component {
    static labels() {
        return ['sdlTitle', 'sdlContent'];
    }

    constructor() {
        super();
        this.state = {};
    }

    render() {
        /* standard props */

        const { data } = this.props;
        const {
            patternAttributes = {},
            titleLabel,
            contentLabel,
            title: titleFromProps,
            content: contentFromProps,
            largeText,
            backgroundColor,
            backgroundImage,
            textColor,
            logicalName,
            removeBottomPadding,
            removeTopPadding,
            alignment,
            header,
            isDecorativeTitle,
            lightText,
        } = data;

        const title = titleLabel ? miniGazelle.label(titleLabel, true) : titleFromProps;
        const content = contentLabel ? miniGazelle.label(contentLabel, true) : contentFromProps;

        /**
         * build the standard style props
         **/

        const inlineStyle = {
            backgroundColor: backgroundColor,
            backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
            color: textColor,
        };

        const headerStyle = {
            color: textColor,
        };

        const wrapperClasses = classNames({
            'container-fluid': true,
            module: true,
            'module-large-text': largeText,
            'standard-text': true,
            'standard-text-no-title': !title,
            'remove-bottom-padding': removeBottomPadding,
            'remove-top-padding': removeTopPadding,
        });

        const classes = classNames({
            'col-md-12 text-left px-0': alignment == 'left',
            'col-md-10 offset-md-1 text-center p-0': alignment == 'center',
            'col-md-12 text-right px-0': alignment == 'right',
        });

        /**
         * All modules default fallback should be 'h2'. If another is required
         * then this should be manually updated by a content publisher.
         */
        const HeaderElement = header ? header : 'h2';

        const headerLogic = isDecorativeTitle ? (
            <div
                className={classNames({
                    [header]: header,
                    h2: !header,
                    'header-light': lightText,
                })}
                style={headerStyle}
                dangerouslySetInnerHTML={{
                    __html: replacePatternAttributes({
                        string: title,
                        patternAttributes,
                    }),
                }}
            />
        ) : (
            <HeaderElement
                className={classNames({
                    'header-light': lightText,
                })}
                style={headerStyle}
                dangerouslySetInnerHTML={{
                    __html: replacePatternAttributes({
                        string: title,
                        patternAttributes,
                    }),
                }}
            />
        );

        return (
            <div className={wrapperClasses} id={logicalName} style={inlineStyle}>
                <div className="container">
                    <div className={classes}>
                        {title && headerLogic}
                        {content && (
                            <div
                                className="inner-content"
                                dangerouslySetInnerHTML={{
                                    __html: replacePatternAttributes({
                                        string: content.replace(/<a/g, '<a style="font-size: 14px"'),
                                        patternAttributes,
                                    }),
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default StandardText;
